@import 'variables.scss';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modalContent {
  position: absolute;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 450px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
}

.modalHeader {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid $lightBorder;
  span {
    font-weight: bold;
    font-size: 18px;
  }
}

.closeButton {
  background: none;
  border: none;
  font-size: 35px;
  line-height: 26px;
  cursor: pointer;
  color: $lightPurple1;
  transition: color 0.3s ease;
  :hover {
    color: #333; /* Muda a cor ao passar o mouse */
  }
}

.modalBody {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 21.6px;
    margin: 20px 0px 10px 0px;
  }

  :first-child {
    margin-top: 0px;
  }
}

.minMaxDateChildren {
  display: flex;
  border: 1px solid $lightBorder;
  align-items: center;
  border-radius: 8px;
  width: 78px;
  justify-content: center;
  padding: 8px 0;

  div {
    width: 80%;
    div {
      width: 80%;
      div {
        border: none;
      }
      span {
        width: 100%;
        div {
          text-align: center;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 1px;
          line-height: 16.8px;
        }
      }
    }
  }
}

.modalFooter {
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  border-top: 1px solid $lightBorder;
  gap: 5px;
}

.customCheckbox {
  color: $black;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  padding: 0 5px;
  height: 34px;
}

.periodContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  @media screen and (max-width: 290px) {
    flex-direction: column;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
  }

  .calendarContainer {
    border: 1px solid $lightGrey;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    div {
      div {
        input {
          height: 19px;
          padding: 9px;
          border: none;
        }
      }
    }
  }
}

.menu {
  border: 1px solid $lightBorder;
  background-color: $white;
  font-size: 14px;
  padding: 0.65rem 0.95rem;
  height: 13.6px;
  margin-top: 0 !important;
  margin-right: 10px;
}

.generalEvaluationContainer {
  display: flex;
  gap: 15px;

  .content {
    display: flex;
    gap: 6px;
  }
}
.formGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1rem;

  .formLabel {
    display: flex;
    margin-right: 1rem;
    font-weight: 500;
    font-size: 14px;
    align-self: flex-start;
    margin-top: 12px;
  }

  .largeSelect {
    border: 1px solid $lightBorder;
    border-bottom: 1px solid $lightBorder;
    border-radius: 8px;
    background-color: $white;
    max-width: 100%;
    font-size: 15px;
    padding: 12px;

    span {
      line-height: 20px;
      white-space: nowrap;
      // max-width: 85px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
